import {Link} from "gatsby"
import React from "react"
import styled from "styled-components";
import COLORS from "../styles/colors";
import GearsImage from "../images/gears-dark.png";

const WorkshopHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    min-height: 300px;

    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
`;

const WorkshopHeaderPanel = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const WorkshopHeaderLeft = styled(WorkshopHeaderPanel)`
    background: linear-gradient(
      rgba(53, 110, 216, 0.8), 
      rgba(53, 110, 216, 0.8)
    ),
    url(${GearsImage});
    background-position: 25% 50%;
    background-repeat: no-repeat;

    h1 {
        color: #fff;
        font-weight: 300;
        line-height: 2.5rem;
        font-size: 2.2rem;
    }
`;

const StyledLink = styled(Link)`
    border: 2px solid ${COLORS.primaryColor};
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    text-decoration: none;
    color: ${COLORS.primaryColor};
    transition: all  0.5s;
    display: inline-block;

    &:hover {
        background-color: ${COLORS.primaryColor};
        color: #fff;
    }
`;

const WorkshopContainer = (props) => {
    const title = props.title === "English Learners / Emergent Bilinguals"
        ? <h1>English Learners /<br />Emergent Bilinguals</h1>
        : <h1>{props.title}</h1>;

    return (
    <WorkshopHeaderContainer>
        <WorkshopHeaderLeft>
            <h1>{title}</h1>
        </WorkshopHeaderLeft>
        <WorkshopHeaderPanel style={{padding: "5%"}}>
            <div>
                <h1>Did You Know?</h1>
                <p>We continually develop workshops upon request.</p>
                <StyledLink to="/contact/">Contact Us</StyledLink>
            </div>
        </WorkshopHeaderPanel>
    </WorkshopHeaderContainer>
    );
};


export default WorkshopContainer;
